<template>
  <div class="administration-theme">
    <v-container fluid class="pa-6">
      <v-row>
        <v-col cols="6">
          <v-card rounded="lg" class="pa-3">
            <v-card-text>


              <!--
              {{$t('primaryColorLabel')}}
              <div class="primary-picker">
                 <v-menu
                  offset-x
                  :close-on-content-click="false"
                  content-class="elevation-2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      text
                      v-bind="attrs"
                      v-on="on"
                      style="display:inline-block"
                    >
                      <v-color-picker
                        dot-size="22"
                        hide-canvas
                        hide-inputs
                        mode="hexa"
                        v-model="primaryColor"
                        swatches-max-height="200"
                        class="simple-picker"
                      ></v-color-picker>
                    </a>
                  </template>
                  <v-list elevation="0">
                    <v-list-item>
                      <v-color-picker
                        dot-size="22"
                        mode="hexa"
                        v-model="primaryColor"
                        swatches-max-height="200"
                      ></v-color-picker>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              {{$t('primaryTextColorLabel')}}
              <div class="primary-picker">
                 <v-menu
                  offset-x
                  :close-on-content-click="false"
                  content-class="elevation-2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      text
                      v-bind="attrs"
                      v-on="on"
                      style="display:inline-block"
                    >
                      <v-color-picker
                        dot-size="22"
                        hide-canvas
                        hide-inputs
                        mode="hexa"
                        v-model="primaryTextColor"
                        swatches-max-height="200"
                        class="simple-picker"
                      ></v-color-picker>
                    </a>
                  </template>
                  <v-list elevation="0">
                    <v-list-item>
                      <v-color-picker
                        dot-size="22"
                        mode="hexa"
                        v-model="primaryTextColor"
                        swatches-max-height="200"
                      ></v-color-picker>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              -->


              {{$t('logoMenuLabel')}}
              <div class="logo-upload pa-4">
                <div class="logo-input menu" :style="{ 'background-image': `url(${logoMenu})` }" @click="openDialogMediatheque('logoMenu')">
                  <span v-if="!logoMenu" class="placeholder">
                    <v-icon>$paperclip_l</v-icon>
                  </span>
                </div>
              </div>
              {{$t('faviconLabel')}}
              <div class="logo-upload pa-4">
                <div class="logo-input favicon" :style="{ 'background-image': `url(${favicon})` }" @click="openDialogMediatheque('favicon')">
                  <span v-if="!favicon" class="placeholder">
                    <v-icon>$paperclip_l</v-icon>
                  </span>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn small depressed color="primary" @click="saveDefaultParameters" class="mt-1">
                  <v-icon left dark small> $save_l </v-icon>
                  {{$t('saveButton')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <MediathequeV2DialogComponent v-model="fileUrl" :openDialogMediatheque="dialogMediatheque" @close="closeDialogMediatheque" :prefilters="prefilters" :availableFilters="availableFilters" :upload="true" :returnUrl="true" />
  </div>
</template>

<script>
import MediathequeV2DialogComponent from '@/components/dialogs/MediathequeV2DialogComponent'

export default {
  name: "AdministrationTheme",
  components: { MediathequeV2DialogComponent },
  data() {
    return {
      primaryColor: "",
      primaryTextColor: "",
      logoMenu: null,
      favicon: null,
      fileUrl: "",
      fileType: "",
      dialogMediatheque: false,
      prefilters: {
        // "brand": "bmw"
        "utilisation": "logo"
      },
      availableFilters: [
          {
              label: "Marques",
              ref: "brand"
          }
      ],
    };
  },
  methods: {
    saveDefaultParameters: function(){
      let payload = {
        primary_color: this.primaryColor,
        primary_text_color: this.primaryTextColor,
        logo_menu: this.logoMenu,
        favicon: this.favicon,
      }
      this.$store.dispatch("settings/SET_DEFAULT_THEME", payload).then((e) => {
        this.primaryColor = this.$store.state.auth.currentUser.parameters.theme.primary_color
        this.primaryTextColor = this.$store.state.auth.currentUser.parameters.theme.primary_text_color
        this.logoMenu = this.$store.state.auth.currentUser.parameters.theme.logo_menu
        this.favicon = this.$store.state.auth.currentUser.parameters.theme.favicon
      })
    },
    openDialogMediatheque (ref) {
      this.dialogMediatheque = true;
      this.fileType = ref;
      this.fileUrl = this[this.fileType];
    },
    closeDialogMediatheque: function(){
      this.dialogMediatheque = false;
      this[this.fileType] = this.fileUrl;
    },
  },
  computed: {
    currentUser: function(){
      return this.$store.state.auth.currentUser
    }
  },
  created() {
    if(this.$store.state.auth.currentUser){
      this.primaryColor = this.$store.state.auth.currentUser.parameters.theme.primary_color
      this.primaryTextColor = this.$store.state.auth.currentUser.parameters.theme.primary_text_color
      this.logoMenu = this.$store.state.auth.currentUser.parameters.theme.logo_menu
      this.favicon = this.$store.state.auth.currentUser.parameters.theme.favicon
    }
  },
  watch: {
    // currentUser: function(val){
    //   this.primaryColor = this.$store.state.auth.currentUser.parameters.primary_color
    //   this.primaryTextColor = this.$store.state.auth.currentUser.parameters.primary_text_color
    // }
  },
};
</script>

<style lang="scss">
  .primary-picker {
    position:relative;
  }
  .simple-picker {
    .v-color-picker__dot {
      width:50px;
      height:50px;
      border-radius:0 !important;
      border: 1px solid var(--v-darkgrey-base) !important;
    }
    .v-color-picker__sliders {
      display:none !important;
    }
  }
  .administration-theme {
    .logo-upload {
      .logo-input {
        &.menu {
          height: 100px;
          width: 100px;
        }
        &.favicon {
          height: 70px;
          width: 70px;
        }
        display: block;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        border-radius: 4px;
        border:1px solid #cccccc;
        padding:15px;
        background-origin: content-box;
      }
      .placeholder {
        background: #F0F0F0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        border-radius: 4px;
      }
      .placeholder:hover {
        background: #E0E0E0;
      }
      .file-input {
        display: none
      }
    }
  }
</style>
